import { useEffect, useRef, useState } from "react";
import commonStyles from "../../commonStyles.module.scss";
import textFieldStyles from "./TextItems.module.scss";
import { APPROVED, APPROVED_AFTER_EDIT, REJECTED } from "../../Constants";
function TextItems({ item, updateActionOnItem }) {
  const engInputRef = useRef(null);
  const hindiInputRef = useRef(null);

  // console.log("item : ", item);
  const [itemAction, setItemAction] = useState("");
  useEffect(() => {
    if (engInputRef.current) {
      engInputRef.current.value = item.engValue;
    }
    if (hindiInputRef.current) {
      hindiInputRef.current.value = item.hindiValue;
    }
  }, [itemAction]);

  function getEngInputValue() {
    return engInputRef.current.value;
  }
  function getHindiInputValue() {
    return hindiInputRef.current.value;
  }

  function editClicked() {
    setItemAction(APPROVED_AFTER_EDIT);
    updateActionOnItem(
      item.itemId,
      APPROVED_AFTER_EDIT,
      () => {
        return getEngInputValue();
      },
      () => {
        return getHindiInputValue();
      }
    );
  }
  function approveClicked() {
    setItemAction(APPROVED);
    updateActionOnItem(item.itemId, APPROVED);
  }
  function rejectClicked() {
    setItemAction(REJECTED);
    updateActionOnItem(item.itemId, REJECTED);
  }
  function resetClicked() {
    setItemAction("");
    updateActionOnItem(item.itemId, "");
  }
  function getEditApproveRejectButtonPanel() {
    return (
      <>
        <button
          className={commonStyles.blackButton}
          onClick={editClicked}
          type="button"
        >
          edit
        </button>
        <button
          id={`approveBtn_${item.fieldName}`}
          className={commonStyles.greenButton}
          onClick={approveClicked}
          type="button"
        >
          approve
        </button>
        <button
          className={commonStyles.brownButton}
          onClick={rejectClicked}
          type="button"
        >
          reject
        </button>
      </>
    );
  }
  function getResetButtonPanel() {
    return (
      <button
        className={commonStyles.blueButton}
        onClick={resetClicked}
        type="button"
      >
        reset
      </button>
    );
  }
  function getFieldContainerClass() {
    return item.itemId % 2 === 1
      ? commonStyles.fieldContainer1
      : commonStyles.fieldContainer;
  }

  return (
    <>
      <div className={getFieldContainerClass()}>
        <strong id={item.fieldName}>{item.fieldName}</strong>
        <div className={textFieldStyles.fieldTitle}>Eng Value</div>
        <div
          id={item.fieldName + "_engValue"}
          className={textFieldStyles.fieldValue}
        >
          {item.engValue}
        </div>
        {itemAction === APPROVED_AFTER_EDIT && (
          <>
            <input type="text" ref={engInputRef} />
            <br />
          </>
        )}
        <div className={textFieldStyles.fieldTitle}>हिंदी वैल्यू</div>
        <div
          id={item.fieldName + "_hindiValue"}
          className={textFieldStyles.fieldValue}
        >
          {item.hindiValue}
        </div>
        {itemAction === APPROVED_AFTER_EDIT && (
          <>
            <input type="text" ref={hindiInputRef} />
            <br />
          </>
        )}
        <br />
        {itemAction === ""
          ? getEditApproveRejectButtonPanel()
          : getResetButtonPanel()}
      </div>
    </>
  );
}

export default TextItems;
